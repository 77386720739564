import { getAge } from 'lib/date';
import {
  AppointmentSummaryOutput,
  DiagnosisSummaryOutput,
  MedicalProblemOutput,
  MedicalRemainingProblemsOutput,
  MeetingContextLocation,
  MeetingContextOutput,
  NoteDetail,
  ScribeOutputType,
  TextOutput,
} from 'node-api/scribe/scribe';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import { camelCaseToSentence } from 'utils/stringHelpers';

const convertMedicalProblemsToString = (problems: MedicalProblemOutput[]) => {
  const problemsAsStrings = problems
    .map(
      (medicalProblem: MedicalProblemOutput) => `
${medicalProblem?.name}

  History:
    ${medicalProblem?.history}

  Data Review:
    ${medicalProblem?.dataReview}

  Subjective Updates:
    ${medicalProblem?.subjectiveUpdates}

  Assessment and Discussion:
    ${medicalProblem?.assessmentAndDiscussion}

  Plan:
    ${medicalProblem?.plan}
`
    )
    .join('\n');

  return `
Problems:
${problemsAsStrings}
  `;
};

const convertMedicalContextToString = (context: MeetingContextOutput) => {
  return `
${context.patientName || 'unknown'} is a ${getAge(context.patientDOB) || 'unknown-age'} year old ${
    context.patientGender
  } who is here for an Initial Evaluation and Assessment for ${
    context.mainProblem || 'unknown-main-problem'
  }. This visit was conducted ${context.appointmentLocation || 'unknown-location'}.\n${
    context.appointmentLocation === MeetingContextLocation.Telehealth &&
    `The patient gave informed consent for telehealth during registration, including rights,
    services, and privacy measures, willingly participating in the virtual consultation.`
  }
`;
};

const convertMedicalDurationToString = (duration: string) => {
  return `During this encounter, I spent approximately ${duration}`;
};

const convertDiagnosisSummaryToString = (diagnosisSummary: DiagnosisSummaryOutput) => {
  const diagnosesFormatted = Object.entries(diagnosisSummary)
    .map(([premise, diagnoses]) => {
      const medicalProblem = camelCaseToSentence(premise);

      return `${medicalProblem}:\n${diagnoses.join(', ')}`;
    })
    .join('\n');

  return `During this encounter, I addressed the following:\n\n${diagnosesFormatted}`;
};

const convertAppointmentSummaryToString = (appointmentSummary: AppointmentSummaryOutput) => {
  return `
Originating site: ${appointmentSummary.originatingSite}
Distant site: ${appointmentSummary.distantSite}
Time started: ${getFormattedDateFromISOString(appointmentSummary.startingTime, 'hh:mm')}
Time ended: ${getFormattedDateFromISOString(appointmentSummary.endingTime, 'hh:mm')}
      `;
};

const convertMedicalRemainingProblemsToString = (
  medicalRemainingProblems: MedicalRemainingProblemsOutput
) => {
  return `
The remaining problems were not addressed today. During this encounter, I reviewed the data
point mentioned in the clinical note, which included face to face and time spent:
${medicalRemainingProblems.medicalProcedures?.join(', ') || 'unknown-medical-procedures'}

I also additionally addressed the following problems:
${medicalRemainingProblems.additionalProblem || 'unknown'}

Based on CMS definitions, I estimate that risk of treatment and diagnostic plan is
${medicalRemainingProblems.mdmCode || 'unknown'}
`;
};

const convertStaticTextToString = (data: TextOutput) => {
  return `${data.text}`;
};

export const prepareSectionsForCopying = (note: NoteDetail): string => {
  if (!note.content) {
    return '';
  }

  return note.content
    .sort((a, b) => a.order - b.order)
    .map((section) => {
      const { outputType, data } = section;

      switch (outputType) {
        case ScribeOutputType.MeetingContext:
          return convertMedicalContextToString(data);

        case ScribeOutputType.MeetingDuration:
          return convertMedicalDurationToString(data.meetingDuration);

        case ScribeOutputType.MedicalProblems:
          return convertMedicalProblemsToString(data);

        case ScribeOutputType.DiagnosisSummary:
          return convertDiagnosisSummaryToString(data);

        case ScribeOutputType.AppointmentSummary:
          return convertAppointmentSummaryToString(data);

        case ScribeOutputType.MedicalRemainingProblems:
          return convertMedicalRemainingProblemsToString(data);

        case ScribeOutputType.StaticText:
          return convertStaticTextToString(data);

        default:
          return '';
      }
    })
    .join('\n');
};
