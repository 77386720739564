import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/patientSummary';

const PatientSummaryContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const PatientSummaryProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PatientSummaryContext.Provider value={{ state, dispatch }}>
      {children}
    </PatientSummaryContext.Provider>
  );
};

export { PatientSummaryContext, PatientSummaryProvider };
