import { Stack } from '@mui/material';
import axios from 'axios';
import { Button, Loading } from 'components/elements';
import { ChartBox, ChartSection } from 'components/general-styles';
import Icon from 'components/icon';
import Toast from 'components/toast';
import { EFeatures } from 'lib/feature/features.types';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useState } from 'react';
import ReactTooltip from 'react-tooltip';
import { EventNames } from 'services/provider-app-mixpanel-events';
import { ProviderAppMixpanelInstance } from 'services/provider-app-mixpanel-service';
import { ClickableElement } from 'services/shared-mixpanel-service';
import { ChartContext } from 'state/contexts/chart';
import { FeaturesContext } from 'state/contexts/features';
import { PatientContext } from 'state/contexts/patient';
import { WeightsContext } from 'state/contexts/weights';
import { ActionTypes as ChartActionTypes } from 'state/reducers/chart';
import { ActionTypes as WeightsActionTypes } from 'state/reducers/weights';
import { debug } from 'utils/helpers';
import Charts from './chart/Chart';
import AddWeight from './chart/manage-weight/add-weight';
import { getChartDataFromCore, getWeightsFromCore, getWeightsFromHealthRecords } from './helpers';
import { ClickableContent, Stats } from './style';
import './weight-bulk-delete.css';
import WeightBulkDelete from './WeightBulkDelete';

const WeightChart = ({ patientId, visible }) => {
  const { state: chartState, dispatch: chartDispatch } = useContext(ChartContext);
  const { state: weightsState, dispatch: weightsDispatch } = useContext(WeightsContext);
  const { state: patientState } = useContext(PatientContext);
  const { state: featuresState } = useContext(FeaturesContext);
  const [loading, setLoading] = useState(true);
  const [showAddWeightModal, setShowAddWeightModal] = useState(false);
  const [showDeleteWeightModal, setShowDeleteWeightModal] = useState(false);

  const readyToRenderWeights = useMemo(
    () => !!weightsState && chartState.patientId === patientId,
    [weightsState, patientId]
  );

  const currentMember = useMemo(
    () => patientState.data.find(({ id }) => id === patientId),
    [patientState]
  );

  const getWeights = async (cancelToken) => {
    try {
      const { weights } = await (!!featuresState?.[EFeatures.ProviderGetWeightsFromHealthRecords]
        ? getWeightsFromHealthRecords(patientId, {
            cancelToken: cancelToken.token,
          })
        : getWeightsFromCore(patientId, {
            cancelToken: cancelToken.token,
          }));

      weightsDispatch({
        type: WeightsActionTypes.SET,
        payload: {
          height: {
            feet: currentMember.feet,
            inches: currentMember.inches,
          },
          initialWeightInLbs: !!currentMember.initial_weight
            ? Number.parseFloat(`${currentMember.initial_weight}`)
            : null,
          weightsInGrams: weights,
        },
      });
      setLoading(false);
    } catch (err) {
      if (!axios.isCancel(err)) {
        debug(err);
        setLoading(false);
      }
    }
  };

  const firstWeightsLoad = async (cancelToken) => {
    if (chartState.patientId !== patientId || !chartState.data.weights.values) {
      if (chartState.patientId !== patientId) {
        chartDispatch({ type: ChartActionTypes.CLEAR });
      }
      await getWeights(cancelToken);
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);

    const cancelToken = axios.CancelToken.source();
    (async () => {
      firstWeightsLoad(cancelToken);
    })();

    return () => {
      cancelToken.cancel();
    };
  }, [patientId]);

  useEffect(() => {
    if (!!weightsState) {
      const cancelToken = axios.CancelToken.source();

      (async () => {
        const { bmi, weight } = weightsState;

        // The type for coreChartData is not accurate
        const coreChartData = await getChartDataFromCore(patientId, {
          cancelToken: cancelToken.token,
        });

        // Still dispatching this since this might load other data that is used at other places within the app
        chartDispatch({
          type: ChartActionTypes.SET,
          payload: {
            patientId,
            data: {
              ...coreChartData,
              current_bmi: bmi.current,
              initial_bmi: bmi.initial,
              initial_weight: weight.initialWeightInLbs,
              weight_loss_percentage: weight.stats.weightLossPercentage,
              weight_loss_total: weight.stats.weightLoss,
            },
            type: 'weights',
          },
        });
      })();

      return () => {
        cancelToken.cancel();
      };
    }
  }, [weightsState]);

  if (!visible) {
    return null;
  }

  if (loading) return <Loading />;

  const toggleAddWeightModel = () => {
    if (!showAddWeightModal) {
      ProviderAppMixpanelInstance.track({
        eventName: EventNames.MemberProfileAddWeightOpenModal,
        targetType: ClickableElement.BUTTON,
        targetLocation: 'weights',
        memberId: currentMember?.id,
      });
    }
    setShowAddWeightModal((prevState) => !prevState);
  };

  const toggleBulkDeleteWeightsModal = () => {
    if (!showDeleteWeightModal) {
      ProviderAppMixpanelInstance.track({
        eventName: EventNames.MemberProfileBulkDeleteWeightsOpenModal,
        targetType: ClickableElement.BUTTON,
        targetLocation: 'weights',
        memberId: currentMember?.id,
      });
    }
    setShowDeleteWeightModal((prevState) => !prevState);
  };

  const handleDeleteWeights = async () => {
    Toast.show('success', 'The weights were removed correctly');

    const cancelToken = axios.CancelToken.source();
    await getWeights(cancelToken);
  };

  return (
    <>
      {showAddWeightModal && <AddWeight onClose={toggleAddWeightModel} />}
      {readyToRenderWeights && (
        <ChartSection>
          <ChartBox>
            <Stats>
              {weightsState.weight.initial && (
                <div>
                  <h4>Starting Weight (BMI)</h4>
                  <p>
                    {weightsState.weight.initial} lbs {`(${weightsState.bmi.initial || 'NA'})`}
                  </p>
                </div>
              )}
              {weightsState.weight.initial && weightsState.weight.stats.weightLoss && (
                <div>
                  <h4>Current Weight (BMI)</h4>
                  <p>
                    {(weightsState.weight.initial - weightsState.weight.stats.weightLoss).toFixed(
                      2
                    )}{' '}
                    lbs {`(${weightsState.bmi.current || 'NA'})`}
                  </p>
                </div>
              )}
              {weightsState.weight.stats.weightLoss && (
                <div>
                  <h4>Weight Lost</h4>
                  <span>(From Starting Weight)</span>
                  <p>
                    {weightsState.weight.stats.weightLoss} lbs (
                    {`${weightsState.weight.stats.weightLossPercentage}` || 'NA'}%)
                  </p>
                </div>
              )}
            </Stats>

            <Stack
              sx={{
                flexDirection: 'row',
                gap: 1,
                justifyContent: 'end',
              }}
              className='chart-top-action-container'>
              <ClickableContent
                data-tip='Add New Weight'
                data-for='add-new-weight'
                key='add-new-weight'>
                <ReactTooltip id='add-new-weight' place='top' type='dark' effect='float' />
                <Button color='white' onClick={toggleAddWeightModel}>
                  <Icon icon='plus' background='teal' color='white' size={10} />
                </Button>
              </ClickableContent>
              <ClickableContent
                data-tip='Bulk delete weights'
                data-for='delete-bulk-weight'
                key='delete-bulk-weight'>
                <ReactTooltip id='delete-bulk-weight' place='top' type='dark' effect='float' />
                <Button color='white' onClick={toggleBulkDeleteWeightsModal}>
                  <Icon icon='remove' background='teal' color='white' size={10} />
                </Button>
              </ClickableContent>
            </Stack>

            {!!weightsState.weight.weights?.length && <Charts />}
            {!!weightsState.weight.weights?.length && showDeleteWeightModal && (
              <WeightBulkDelete
                onClose={toggleBulkDeleteWeightsModal}
                onUpdateWeights={handleDeleteWeights}
              />
            )}
          </ChartBox>
        </ChartSection>
      )}
    </>
  );
};

WeightChart.propTypes = {
  patientId: PropTypes.number.isRequired,
};

WeightChart.defaultProps = {};

export default WeightChart;
