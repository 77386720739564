import { Box, MenuItem, Select, Typography } from '@mui/material';
import { getAge } from 'lib/date';
import { MedicalProblem } from 'node-api/scribe/medical';
import { MeetingContextContentType, MeetingContextLocation } from 'node-api/scribe/scribe';
import { NoteEditionHandlers } from './render-sections';

type Props = { content: MeetingContextContentType; handlers: NoteEditionHandlers };

export const MeetingContextSection = ({ content, handlers }: Props) => {
  const renderMainProblemSelector = () => {
    return (
      <Select
        id='meeting-context-problem-select'
        size='small'
        sx={{
          margin: '0 1em 1em 1em',
          width: '10em',
          fontSize: '15px',
          borderRadius: '40px',
          height: '3em',
        }}
        value={content.data.mainProblem}
        inputProps={{
          id: 'diagnosis-duration-select-input',
        }}
        onChange={(v) => {
          handlers.handleChangeMeetingContext({
            ...content,
            data: {
              ...content.data,
              mainProblem: v.target.value as MedicalProblem,
            },
          });
        }}>
        {Object.entries(MedicalProblem).map(([problemKey, problemName]) => (
          <MenuItem key={`context-${problemKey}`} value={problemName}>
            {problemName}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <Box mb={3}>
      <Typography textAlign='justify'>
        {content.data.patientName} is a {getAge(content.data.patientDOB)} year old{' '}
        {content.data.patientGender} who is here for an Initial Evaluation and Assessment for
        {renderMainProblemSelector()} . This visit was conducted {content.data.appointmentLocation}.{' '}
        {content.data.appointmentLocation === MeetingContextLocation.Telehealth && (
          <>
            The patient gave informed consent for telehealth during registration, including rights,
            services, and privacy measures, willingly participating in the virtual consultation.
          </>
        )}
      </Typography>
    </Box>
  );
};
