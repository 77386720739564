import { Box, TextField, Typography } from '@mui/material';
import { AppointmentSummaryContentType } from 'node-api/scribe/scribe';
import { NoteEditionHandlers } from './render-sections';

type Props = {
  content: AppointmentSummaryContentType;
  handlers: NoteEditionHandlers;
};

export const AppointmentSummarySection = ({ content, handlers }: Props) => {
  return (
    <Box mb={2}>
      <Box mb={2}>
        <Typography mb={1}>Originating site:</Typography>
        <TextField
          multiline
          value={content.data.originatingSite}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                originatingSite: v.target.value,
              },
            })
          }
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>Distant site:</Typography>
        <TextField
          multiline
          value={content.data.distantSite}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                distantSite: v.target.value,
              },
            })
          }
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>Time started:</Typography>
        <TextField
          multiline
          value={content.data.startingTime}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                startingTime: v.target.value,
              },
            })
          }
        />
      </Box>

      <Box mb={2}>
        <Typography mb={1}>Time ended:</Typography>
        <TextField
          multiline
          value={content.data.endingTime}
          fullWidth
          onChange={(v) =>
            handlers.handleChangeAppointmentSummary({
              ...content,
              data: {
                ...content.data,
                endingTime: v.target.value,
              },
            })
          }
        />
      </Box>
    </Box>
  );
};
