import { Box, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { CPTCode } from 'node-api/scribe/medical';
import {
  EvaluationAndManagementByCPTCode,
  MeetingDurationContentType,
} from 'node-api/scribe/scribe';
import { NoteEditionHandlers } from './render-sections';

type Props = { content: MeetingDurationContentType; handlers: NoteEditionHandlers };

export const MeetingDurationSection = ({ content, handlers }: Props) => {
  return (
    <Box className='note-preview-duration'>
      <Typography mb={1}>During this encounter, I spent approximately</Typography>
      <FormControl fullWidth>
        <Select
          fullWidth
          key={content.data.meetingDurationRange}
          value={content?.data?.cptCode}
          onChange={(v) => {
            if (content) {
              if (content.sectionId) {
                const cptCode = Number(v.target.value);

                const { meetingDurationRange } = EvaluationAndManagementByCPTCode[cptCode];

                handlers.handleChangeMeetingDuration({
                  ...content,
                  data: {
                    meetingDuration: meetingDurationRange, // TODO: For now, check if this property is necessary
                    meetingDurationRange,
                    cptCode: v.target.value as CPTCode,
                  },
                });
              }
            }
          }}>
          {Object.entries(EvaluationAndManagementByCPTCode).map(([cptCode, evaluationBy]) => (
            <MenuItem key={cptCode} value={cptCode}>
              {`${evaluationBy.meetingDurationRange} - ${cptCode}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
