import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, Typography } from '@mui/material';
import { MeetingSummaryStatus, NoteDetail } from 'node-api/scribe/scribe';
import { renderSectionsByOutputType } from './section';

export type Props = {
  note: NoteDetail;
  handleMarkAsReviewed: (status: MeetingSummaryStatus.REVIEWED) => void;
};

export const NoteEditionPreview = ({ note, handleMarkAsReviewed }: Props) => {
  return (
    <Box className='note-edition-preview-content'>
      <Box className='note-preview-content-header'>
        <Typography
          component='h4'
          fontWeight={600}
          color='#353535'
          sx={{
            fontSize: '18px !important',
          }}>
          Preview
        </Typography>

        <Button
          size='small'
          startIcon={<CheckIcon />}
          onClick={() => handleMarkAsReviewed(MeetingSummaryStatus.REVIEWED)}
          style={{
            fontFamily: 'Proxima Nova Rg',
            color: '#1a667a',
            textTransform: 'capitalize',
            fontWeight: 500,
            fontSize: '1em',
            padding: 0,
          }}>
          Mark as reviewed and copy
        </Button>
      </Box>

      {note.content
        ?.sort((a, b) => a.order - b.order)
        .map((section) => {
          return renderSectionsByOutputType(section.outputType, section);
        })}
    </Box>
  );
};
