import {
  CPTCode,
  MDMCode,
  MedicalBehavioralProblem,
  MedicalProblem,
  MedicalProcedure,
  MeetingDurationRange,
} from './medical';

export enum MeetingSummaryStatus {
  READY_FOR_REVIEW = 'READY_FOR_REVIEW',
  REVIEWED = 'REVIEWED',
}

export enum ScribeOutputType {
  MeetingDuration = 'meeting-duration',
  MeetingContext = 'meeting-context',
  MedicalProblems = 'medical-problems',
  MedicalRemainingProblems = 'medical-remaining-problems',
  DiagnosisSummary = 'diagnosis-summary',
  AppointmentSummary = 'appointment-summary',
  StaticText = 'static-text',
}

export enum MeetingContextLocation {
  Telehealth = 'Telehealth',
  InPerson = 'In-person',
  Unknown = 'Unknown',
}

export enum MeetingContextPatientGender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
  Unknown = 'Unknown',
}

export interface MeetingContextOutput {
  patientDOB: string; // Date as string
  patientName: string;
  patientGender: MeetingContextPatientGender;
  appointmentLocation: MeetingContextLocation;
  mainProblem?: MedicalProblem;
}

export interface MeetingDurationOutput {
  cptCode: CPTCode;
  meetingDuration: string; // in minutes, as string
  meetingDurationRange: MeetingDurationRange;
}

export interface MedicalProblemOutput {
  name: string;
  history?: string;
  dataReview?: string;
  icd10Code: string;
  subjectiveUpdates?: string;
  plan?: string;
  assessmentAndDiscussion?: string; // TODO: use this section
}

export interface MedicalRemainingProblemsOutput {
  mdmCode?: MDMCode;
  additionalProblem?: MedicalBehavioralProblem;
  medicalProcedures?: MedicalProcedure[]; // note: up to 5
}

export interface DiagnosisSummaryOutput {
  stableChronicDecease?: string[];
  minorOrSelfLimitedProblems?: string[];
  acuteUncomplicatedIllnessOrInjury?: string[];
  acuteComplicatedInjury?: string[];
  acuteIllnessWithSystemicSymptoms?: string[];
  acuteOrChronicIllnessOrInjury?: string[];
  chronicDiseaseOrTreatmentSideEffect?: string[];
  severeChronicDiseaseOrTreatmentSideEffect?: string[];
  newUndiagnosedProblem?: string[];
}

export interface AppointmentSummaryOutput {
  originatingSite: string; // default text as Patient’s home
  distantSite: string; // default text as clinic name
  startingTime: string; //  as date in the following format 12:00 pm
  endingTime: string; // as time in the following format 1:00 pm
}

export interface TextOutput {
  text: string;
}

export type MeetingSummaryResultsOutput =
  | MeetingContextOutput
  | MeetingDurationOutput
  | MedicalProblemOutput
  | MedicalRemainingProblemsOutput
  | DiagnosisSummaryOutput
  | AppointmentSummaryOutput
  | TextOutput;

export interface MeetingSummarySection {
  id: number;
  meetingSummaryId: number;
  meetingTemplateSectionId: number | null;
  resolvedPrompt: string;
  results: MeetingSummaryResultsOutput;
  outputType: ScribeOutputType;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export type MeetingSummary = {
  id: number;
  meetingId: number;
  meetingTemplateId: number;
  status: MeetingSummaryStatus;
  createdAt: string;
  updatedAt: string;
};

export type ProviderNoteData = {
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
};

export type NoteExcerpt = {
  meetingSummary: MeetingSummary;
  meeting: Meeting;
  meetingType: MeetingType;
};

export enum MeetingTypeStatus {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED',
}

export enum MeetingStatus {
  PENDING = 'PENDING',
  TRANSCRIBING = 'TRANSCRIBING',
  COLLECTING_DATA = 'COLLECTING_DATA',
  SUMMARIZING = 'SUMMARIZING',
  SUMMARY_COMPLETED = 'SUMMARY_COMPLETED',
}

export type Meeting = {
  id: number;
  meetingTypeId: number;
  internalProviderId: string;
  internalPatientId: string;
  internalAppointmentId: string;
  rawAppointment: {
    [key: string]: string;
  };
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
  status: MeetingStatus;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
};

export type MeetingType = {
  id: number;
  customerId: number;
  medicalSpecialtyId: number | null;
  meetingTemplateId: number;
  internalCode: string;
  status: MeetingTypeStatus;
  createdAt: string;
  updatedAt: string;
};
export interface MeetingContextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingContext;
  data: MeetingContextOutput;
}

export interface MeetingDurationContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MeetingDuration;
  data: MeetingDurationOutput;
}

export interface MedicalProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalProblems;
  data: MedicalProblemOutput[];
}

export interface MedicalRemainingProblemsContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.MedicalRemainingProblems;
  data: MedicalRemainingProblemsOutput;
}

export interface DiagnosisSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.DiagnosisSummary;
  data: DiagnosisSummaryOutput;
}

export interface AppointmentSummaryContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.AppointmentSummary;
  data: AppointmentSummaryOutput;
}

export interface StaticTextContentType {
  sectionId: string;
  order: number;
  outputType: ScribeOutputType.StaticText;
  data: TextOutput;
}

export type SummaryContentType =
  | DiagnosisSummaryContentType
  | MedicalProblemsContentType
  | MedicalRemainingProblemsContentType
  | MeetingDurationContentType
  | MeetingContextContentType
  | AppointmentSummaryContentType
  | StaticTextContentType;

export type NoteSectionsWithStatus = {
  content?: SummaryContentType[];
  status: MeetingSummaryStatus;
};

export type UpdateSummaryResponse = {
  status: boolean;
  note?: NoteDetail;
};

export type GetAllSummariesPayload = {
  internalProviderId?: string;
  externalProviderId?: string;
  internalPatientId: string;
  specialties?: string;
  page?: number;
  month: string;
  pageSize?: number;
  status?: MeetingSummaryStatus;
};

export type NoteDetail = {
  meeting: Meeting;
  meetingType: MeetingType;
  meetingSummary: MeetingSummary;
  date: string;
  provider: {
    firstName: string;
    lastName: string;
    specialty: string;
  };
} & NoteSectionsWithStatus;

export type GetAllSummariesByDateResponse = {
  data: { [date: string]: (NoteExcerpt & ProviderNoteData)[] };
};

export const EvaluationAndManagementByCPTCode: {
  [cptCode: number]: {
    meetingDurationRange: MeetingDurationRange;
  };
} = {
  99212: {
    meetingDurationRange: MeetingDurationRange.TenToNineteen,
  },
  99213: {
    meetingDurationRange: MeetingDurationRange.TwentyToTwentyNine,
  },
  99214: {
    meetingDurationRange: MeetingDurationRange.ThirtyToThirtyNine,
  },
  99215: {
    meetingDurationRange: MeetingDurationRange.FortyToSixtyFixe,
  },
};
