import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/tag';

const TagContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const TagProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return <TagContext.Provider value={{ state, dispatch }}>{children}</TagContext.Provider>;
};

export { TagContext, TagProvider };
