import { Box, TextField, Typography } from '@mui/material';
import { StaticTextContentType } from 'node-api/scribe/scribe';
import { NoteEditionHandlers } from './render-sections';

type Props = { content: StaticTextContentType; handlers: NoteEditionHandlers };

export const StaticTextSection = ({ content, handlers }: Props) => {
  return (
    <Box mb={2}>
      <Typography mb={1}>Text:</Typography>
      <TextField
        multiline
        value={content.data.text}
        fullWidth
        onChange={(v) =>
          handlers.handleChangeTextSection({
            ...content,
            data: {
              ...content.data,
              text: v.target.value,
            },
          })
        }
      />
    </Box>
  );
};
