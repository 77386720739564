export enum EventNames {
  BulkActionsClickBulkMessage = 'bulk-actions: click bulk messages button',
  BulkMessageMarkAsReviewed = 'bulk-message: mark as reviewed',
  BulkMessageSendMessage = 'bulk-message: send message',
  CardDetailClickCommentButton = 'cards/card-detail: click comment button',
  CardDetailClickReviewButton = 'cards/card-detail: click review button',
  CardsClickTab = 'cards: click tab',
  CardWithPendingCommentsClickCommentButton = 'cards/cards-with-pending-comments: click comment button',
  CardWithPendingCommentsClickReviewButton = 'cards/cards-with-pending-comments: click review button',
  FeatureRatingClick = 'feature-rating: click',
  FeatureRatingComment = 'feature-rating: comment',
  MemberCallJoin = 'member-call/join',
  MemberCallJoinAppointmentSummary = 'member-call/appointment-summary/video-call: join call',
  MemberCallPatient = 'member-call/member-profile/video-call: join call',
  MemberCallChatLink = 'member-call/chat/video-call: click link to join call ',
  MemberGetCallLink = 'member-call/service/video-call: creating meeting',
  MemberProfileAddWeightOpenModal = 'member-profile/add-weight: open modal',
  MemberProfileBulkDeleteWeightsDeleteWeights = 'member-profile/bulk-delete-weights: delete weights',
  MemberProfileBulkDeleteWeightsOpenModal = 'member-profile/bulk-delete-weights: open modal',
  MemberProfileBulkDeleteWeightsSelectWeight = 'member-profile/bulk-delete-weights: select weight',
  MemberProfileFromEmailClickScheduleButton = 'member-profile-from-email/calendar: click schedule button',
  MemberProfileOpen = 'member-profile/open',
  MemberScheduleViewOpenEventDetail = 'member-schedule:event-detail',
  MetricsCompleteDashboardView = 'metrics/complete-dashboard:view',
  MetricsFilterClinicChange = 'metrics/filter/clinic:change',
  MetricsFilterPanelChange = 'metrics/filter/panel:change',
  MetricsFilterProgramChange = 'metrics/filter/program:change',
  ModalClose = 'modal/close',
  PanelActionDrawerToggle = 'panel-table:drawer-toggle',
  PanelBulkDismissAttentionApply = 'panel-table:bulk-dismiss-attention:apply',
  PanelBulkDismissAttentionCancelModal = 'panel-table:bulk-dismiss-attention:cancel',
  PanelBulkDismissAttentionOpenModal = 'panel-table:bulk-dismiss-attention:open',
  PanelFiltersReset = 'panel-table:filters:reset',
  PanelFiltersSave = 'panel-table:filters:save-default',
  PanelFiltersUpdate = 'panel-table:filters:update',
  PanelKeyboardNavigation = 'panel-table:keyboard-navigation',
  PanelListSelect = 'panel-table:list-select',
  PanelSettingsCancelModal = 'panel-table:settings:cancel',
  PanelSettingsConfirm = 'panel-table:settings:save',
  PanelSettingsOpenModal = 'panel-table:settings:open',
  PanelSortUpdate = 'panel-table:sort:update',
  RecentCardEdit = 'recent-card-edit: click edit button',
  ReviewListsClickInfoButton = 'review-lists: click info button',
  WeeklyViewPreviewEvent = 'weekly-view:event-preview',
  WeeklyViewRotateTimezone = 'weekly-view:timezone-rotate',
}
