import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, IconButton, Snackbar, Typography } from '@mui/material';
import Loader from 'components/elements/loading/Loader';
import Toast from 'components/toast';
import {
  GetAllSummariesByDateResponse,
  MeetingSummaryStatus,
  NoteDetail,
  NoteExcerpt,
} from 'node-api/scribe/scribe';
import ScribeClient from 'node-api/scribe/scribe-client';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { getFormattedDateFromISOString } from 'utils/dateHelpers';
import '../css/notes.css';
import './css/note-edition.css';
import './css/note-preview.css';
import './css/note.css';
import { prepareSectionsForCopying } from './format';
import { NoteEdition } from './NoteEdition/NoteEdition';
import { renderSectionsByOutputType } from './NoteEditionPreview/section';

type Props = {
  noteExcerpt: NoteExcerpt;
  fetchSummaries: () => Promise<GetAllSummariesByDateResponse>;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
};

export const NotePreview = ({ noteExcerpt, fetchSummaries, setOpenModal }: Props) => {
  const [note, setNote] = useState<NoteDetail | null>(null);
  const [canEditNote, setCanEditNote] = useState<boolean>(false);
  const [openCopiedToClipboardMessage, setOpenCopiedToClipboardMessage] = useState<boolean>(false);

  const handleMarkAsReviewed = async () => {
    navigator.clipboard.writeText(note ? prepareSectionsForCopying(note) : '');
    setOpenCopiedToClipboardMessage(true);

    if (note?.status === MeetingSummaryStatus.REVIEWED) {
      Toast.show('success', 'This note was reviewed');
      return;
    }

    const client = new ScribeClient();

    const response = await client.updateSummary(noteExcerpt.meetingSummary.id, {
      status: MeetingSummaryStatus.REVIEWED,
    });

    if (!response.status || !response.note) {
      Toast.show('error', 'There was an error updating note');
      return;
    }

    setNote(response.note);

    Toast.show('success', 'Note updated successfully');

    await fetchSummaries();

    setOpenModal(false);
  };

  const handleEditNote = () => setCanEditNote(!canEditNote);

  useEffect(() => {
    (async () => {
      const client = new ScribeClient();

      const response = await client.getSummary(noteExcerpt.meetingSummary.id);

      if (!response) {
        Toast.show('error', 'There was an error obtaining the note');
        return;
      }

      setNote(response);
    })();
  }, []);

  return note && canEditNote ? (
    <NoteEdition
      note={note}
      fetchSummaries={fetchSummaries}
      setOpenModal={(v) => {
        setCanEditNote(!canEditNote);
        setOpenModal(v);
      }}
    />
  ) : (
    <Box className='note-preview-container'>
      {!note ? (
        <Loader
          style={{
            alignContent: 'center',
            justifyContent: 'center',
            margin: '25% 0',
          }}
        />
      ) : (
        <>
          <Box className='note-preview-header'>
            <Box display='flex' alignItems='center'>
              <Box className='note-list-row-icon' mr={1} />
              <Typography
                mr={1}
                sx={{
                  color: '#404040',
                  opacity: '60%',
                }}>{`${note.meetingType.internalCode} ${getFormattedDateFromISOString(
                note.meeting.createdAt,
                'h:mma'
              )}`}</Typography>
              <Typography
                color='#323648'
                mr={
                  1
                }>{`${note.provider.firstName} ${note.provider.lastName}, ${note.provider.specialty}`}</Typography>
            </Box>

            <IconButton size='small' onClick={() => setOpenModal(false)}>
              <Close />
            </IconButton>
          </Box>

          <Box className='note-preview-content-container'>
            <Box className='note-preview-content-header' mr={1}>
              <Typography
                component='h4'
                fontWeight={600}
                color='#353535'
                sx={{
                  fontSize: '18px !important',
                }}>
                Review Notes
              </Typography>
              <Box>
                <Button
                  variant='text'
                  startIcon={<EditIcon />}
                  onClick={handleEditNote}
                  style={{
                    color: '#1a667a',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                    display:
                      note.meetingSummary.status === MeetingSummaryStatus.REVIEWED
                        ? 'none'
                        : 'initial',
                  }}>
                  Edit
                </Button>

                <Snackbar
                  message='Copied to clipboard'
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  autoHideDuration={2000}
                  onClose={() => setOpenCopiedToClipboardMessage(false)}
                  open={openCopiedToClipboardMessage}
                />

                <Button
                  variant='text'
                  disabled={note.meetingSummary.status === MeetingSummaryStatus.REVIEWED}
                  startIcon={<CheckIcon />}
                  onClick={handleMarkAsReviewed}
                  style={{
                    fontFamily: 'Proxima Nova Rg',
                    color: '#1a667a',
                    textTransform: 'capitalize',
                    fontWeight: 500,
                  }}>
                  {note.meetingSummary.status === MeetingSummaryStatus.REVIEWED
                    ? 'Marked as reviewed'
                    : 'Mark as reviewed and copy'}
                </Button>
              </Box>
            </Box>

            {note.content
              ?.sort((a, b) => a.order - b.order)
              ?.map((section) => {
                return renderSectionsByOutputType(section.outputType, section);
              })}
          </Box>
        </>
      )}
    </Box>
  );
};
