import { createContext, Dispatch, FC, PropsWithChildren, useReducer } from 'react';
import { Action, initialState, reducer, State } from '../reducers/patientTags';

const PatientTagsContext = createContext<{ state: State; dispatch: Dispatch<Action> }>({
  state: initialState,
  dispatch: () => null,
});

const PatientTagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PatientTagsContext.Provider value={{ state, dispatch }}>
      {children}
    </PatientTagsContext.Provider>
  );
};

export { PatientTagsContext, PatientTagsProvider };
